.Code {
    font-weight: var(--font-weight-lg);
}
.Date {
    text-align: right;
    opacity: .4;
}

.Empty {
    padding: var(--padding-xl) var(--padding-lg);
    text-align: center;
}

.Seedcodes {
    background-color: var(--primary-card-background-color-light);
    border-bottom-left-radius: var(--button-radius-md);
    border-bottom-right-radius: var(--button-radius-md);
    color: var(--secondary-text-color);
}

.Seedcode {
    border-bottom: 1px solid var(--primary-body-background-color);
    border-radius: 0 !important;
    padding: var(--padding-lg) !important;
}

.Seedcode:last-child {
    border-bottom: none;
}

.QrIcon {
    margin-right: var(--margin-md);
    opacity: .3;
}