.Container {
    margin-top: 16px;
}

.Intro {
    padding: var(--margin-lg) 0 var(--margin-xl) 0 !important;
}

.Intro h2 {
    margin: 0 0 8px 0;
    line-height: 1;
    text-align: center;
}

.Intro > p {
    text-align: center;
}

.release {
    background-color: #0E0E11;
    border-radius: 4px;
    margin-bottom: 4px;
    max-width: 100%;
    width: 100%;
    padding: 24px;
}

/* DATE */
.release > span {

}

.release h3:first-child {
    font-weight: 300;
    margin: auto auto var(--margin-lg) auto;
}

.release > p:last-child {
    opacity: .3;
    margin-top: 24px;
}

.release ul {
    margin: 0;
}

.release blockquote {
    background-color: rgba(255,255,255,0.075);
    border-radius: 18px;
    display: inline-block;
    padding: 8px 16px !important;
}

.release blockquote p {
    font-size: 12px !important;
    font-weight: 600;
    line-height: 1;
    text-transform: uppercase;
    padding: 0;
}