.AuthContainer {
    /* background-color: rgba(0,0,0, 0.3); */
    left: 50%;
    padding: 96px var(--padding-md);
    position: relative;
    text-align: center;
    transform: translate(-50%, 0);
    max-width: 295px;
    width: 100%;
}

@media (max-width: 991.98px) {
    .AuthContainer {
        padding: var(--padding-xxl) var(--padding-md);
    }
}

.AuthPage {
    background-position: center center;
}