.CardNumber {
    border: 1px solid #ccc;
    border-radius: var(--button-radius-sm);
    box-shadow: inset 2px 2px 4px rgba(0,0,0,0.1);
    display: inline-block;
    padding: 12px;
    margin-bottom: 12px;
    /* margin-right: 5%;
    width: 60%; */
}

.CardCvc {
    border: 1px solid #ccc;
    border-radius: var(--button-radius-sm);
    box-shadow: inset 2px 2px 4px rgba(0,0,0,0.1);
    display: inline-block;
    padding: 12px;
    margin-bottom: 12px;
    /* 
    width: 15%; */
}

.CardExpiry {
    border: 1px solid #ccc;
    border-radius: var(--button-radius-sm);
    box-shadow: inset 2px 2px 4px rgba(0,0,0,0.1);
    display: inline-block;
    padding: 12px;
    margin-bottom: 12px;
    /* margin-right: 5%;
    width: 15%; */
}

.PayButton {
    float: right;
    padding: var(--padding-md) var(--padding-lg) !important;
    margin-bottom: var(--margin-xxl);
    position: relative;
    top: var(--margin-md);
}

.PayButton:disabled {
    background-color: rgba(0,0,0,0.2);
    opacity: .6;
    pointer-events: none;
}
