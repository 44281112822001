.Dashboard {
    margin: 18px 0;
}

@media (max-width: 769px) {
    .Dashboard {
        margin: 0 !important;
    }
}

.products {
    margin-bottom: 6px;
}

.assets,.transactions {
    margin-top: var(--padding-xl);
}

.icon {
    margin-right: 12px;
    opacity: .4;
}

.infoSubtext {
    background: rgba(255,255,255,0.05);
    /* border: 1px solid rgba(255,255,255,0.2); */
    border-radius: 4px;
    font-weight: 400;
    font-size: 18px;
    margin-top: 6px;
    /* margin-bottom: var(--padding-xl); */
    padding: var(--padding-md);
    text-align: center;
}

.usersAssets, .usersInfo, .usersProducts, .walletStatus {
    margin-bottom: var(--padding-md);
}

@media (max-width: 769px) {
    .walletStatus {
        margin-bottom: var(--padding-lg);
    }
}