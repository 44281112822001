.MailchimpSubscribe {
    display: inline-block;
    width: auto;
}

.MailchimpSubscribe div {
    display: inline-block;
}

.MailchimpSubscribe input[type="submit"] {
    background-color: var(--primary-color) !important;
    border: none;
    border-radius: var(--button-radius-sm);
    box-sizing: border-box;
    color: #fff;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-lg);
    padding: var(--padding-md) var(--padding-lg);
    position: relative;
    top: -2px;
    margin-left: var(--margin-md);
}

.MailchimpSubscribe input[type="submit"]:hover {
    background-color: var(--primary-color-light) !important;
    color: #fff;
}

.MailchimpSubscribe input[type="email"] {
    border: none;
    border-radius: var(--button-radius-sm);
    font-size: var(--font-size-lg);
    padding: 9px var(--padding-lg);
}


@media (max-width: 769px) {
    .MailchimpSubscribe input[type="submit"] {
        top: 0;
        margin: var(--margin-md) 0 0 0;
        width: 100%;
    }

    .MailchimpSubscribe input[type="email"] {
        width: 100%;
    }
}