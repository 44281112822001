.Container {
    margin: 18px 0 32px 0;
}

.AffiliateList, .AffiliateListPaid {
    margin-bottom: 32px;
}

.AffiliateListPaid {
    opacity: .5;
}

.CopyLink {
    background-color: rgba(0,0,0,0.2);
    border: 2px solid rgba(255,255,255,0.1);
    border-radius: 4px;
    display: block;
    font-size: 24px;
    line-height: 1;
    padding: 16px 24px;
    margin-bottom: 24px;
}

.CopyLink:hover {
    background-color: rgba(0,0,0,0.4) !important;
}

.SalesContainer {
    margin: var(--margin-xl) 0;
}

.title {
    margin: 0 0 var(--margin-lg) 0;
    padding: 0;
}