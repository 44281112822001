.description {
    line-height: 1;
}

.listItemContainer {
    background-color: hsl(240, 6%, 28%);
    border-radius: 4px;
    margin-bottom: 4px;
    padding: 24px;
}

.listItemContainer:last-child {
    margin-bottom: 0;
}

.name {
    background-color: transparent !important;
    margin: 0 0 8px 0 !important;
    padding: 0 !important;
}