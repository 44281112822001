.avatar {
    width: 100%;
}

.avatarContainer {
    border: 4px solid #fff;
    border-radius: 45px;
    float: right;
    overflow: hidden;
    height: 90px;
    width: 90px;
}

.Container {
    margin-top: 56px;
}

.CardBody {
    background-color: #0E0E11 !important;
    border-radius: var(--button-radius-sm);
}

.ChangeUsernameButton {
    padding: var(--padding-sm) var(--padding-lg) !important;
}

.ColorPicker {
    /* border: 4px solid rgba(0,0,0,0.2); */
    border-radius: var(--button-radius-sm);
    height: 125px;
    overflow: hidden;
    width: 100%;
}

.FormBio {
    box-sizing: border-box;
    padding: var(--padding-md2) var(--padding-md2) !important;
    min-height: 120px !important;
}

.FormError {
    color: var(--color-warning);
}

.FormField {
    margin-bottom: var(--margin-md);
}

.FormGroup {
    /* margin-bottom: var(--margin-lg); */
}

.FormSubGroup {
    margin-bottom: var(--margin-md);
}

.FormSubGroup:last-child {
    margin-bottom: 0;
}

.FormLabel {
    color: #fff !important;
    font-size: var(--font-size-md);
    margin-bottom: var(--margin-md);
}

.RandomizeButton {
    /* float: right; */
    padding: var(--padding-md) var(--padding-lg) !important;
}

.SaveButton {
    float: right;
    padding: var(--padding-md) var(--padding-lg) !important;
    margin-bottom: var(--margin-xxl);
}

.Username {
    display: none;
}