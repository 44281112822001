@import 'components/kit/vendors/antd/style';
@import 'components/mixins.scss';





.user-menu {
  padding-right: 15px;
  position: relative;
  float: right;
  top: 12px;
}

/* Username */
.user-menu a.username:link, .user-menu a.username:visited {
  color: var(--primary-link-color);
  text-decoration: none;
  font-family: var(--body-font);
  font-size: var(--font-size-md) !important;
  position: relative;
  top: 2px;
}

.user-menu a.username:hover, .user-menu a.username:active {
  color: var(--primary-link-hover-color) !important;
}






.user-menu .ant-badge-count {
  background: var(--primary-color);
}

.user-menu .ant-badge-count .ant-scroll-number-only-unit {
  color: #fff;
  font-size: 12px !important;
  position: relative;
  top: -8px;
}




/* ant dropdown */
.ant-dropdown-menu {
  // background-color: rgb(14, 14, 17) !important;
  padding: 0 !important;
}
.ant-dropdown-menu-item {
  padding: 0 !important;
}

.ant-dropdown-menu-item-divider {
  margin: 0 !important;
}

.ant-dropdown-menu-item a {
  font-family: var(--body-font);
  font-weight: var(--font-weight-md2);
  margin: 0 !important;
  padding: 5px 12px !important;
}

.ant-dropdown-menu-item a svg {
  margin: 0 var(--margin-md) 0 0;
}

// .dropdown {
//   padding-left: var(--padding-md);
//   padding-right: var(--padding-lg);
//   position: relative;
//   cursor: pointer;

//   &::after {
//     opacity: 0.5;
//     color: $gray-5;
//     position: absolute;
//     top: 50%;
//     right: 0;
//     margin-top: -2px;
//     display: inline-block;
//     width: 0;
//     height: 0;
//     margin-left: 0.255em;
//     vertical-align: 0.255em;
//     content: '';
//     border-top: 0.3em solid;
//     border-right: 0.3em solid transparent;
//     border-bottom: 0;
//     border-left: 0.3em solid transparent;
//     @include transition-slow();
//   }

//   &:hover {
//     color: $blue;

//     &::after {
//       color: $gray-6;
//     }
//   }
// }

.user-menu .avatar {
  background-color: var(--color-dark) !important;
}
