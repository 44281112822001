.Empty {
    padding: var(--padding-xl) var(--padding-lg);
    text-align: center;
}

.Products {
    background-color: var(--primary-card-background-color-light);
    border-bottom-left-radius: var(--button-radius-sm);
    border-bottom-right-radius: var(--button-radius-sm);
    color: var(--secondary-text-color);
}

.Product {
    border-bottom: 1px solid var(--primary-body-background-color);
    border-radius: 0 !important;
    padding: var(--padding-lg) !important;
}

.Product:last-child {
    border-bottom: none;
}