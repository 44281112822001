.assetLink {
    display: block;
    width: 100%;
}
.associatedCount {
    font-size: 14px;
    font-weight: 400;
    left: 12px;
    line-height: 1;
    margin: 0;
    opacity: .35;
    position: relative;
    top: 5px;
}

.button {
    font-size: 14px;
    font-weight: 900 !important;
    margin-top: 24px;
    margin-right: 8px;
}

.button button {
    padding: 4px 8px !important;
}

.description {
    line-height: 1;
}

.listItemContainer {
    background-color: #0E0E11;
    border-radius: 4px;
    margin-bottom: 4px;
    max-width: 100%;
    padding: 24px;
    text-align: left;
    width: 100%;
}

.listItemContainer:last-child {
    margin-bottom: 0;
}

.name {
    background-color: transparent !important;
    clear: left;
    font-size: 20px;
    font-weight: 400;
    line-height: 20px;
    margin: 0 0 12px 0 !important;
    overflow: hidden;
    padding: 0 !important;
    text-overflow: ellipsis !important;
    white-space: nowrap;
}

.thumbnail {
    border: 3px solid rgba(255,255,255,0.3);
    border-radius: 30px;
    clear: both;
    float: right;
    margin-left: 12px;
    max-width: 59px;
    position: relative;
}

@media (max-width: 769px) {
    .thumbnail {
        float: none;
        width: 100%;
        margin-left: 0;
        margin-bottom: 12px;
        max-width: 100%;
    }
}

.tokenId {
    font-size: 12px;
    font-weight: 200;
    line-height: 1;
    margin-bottom: 18px;
    max-width: 240px;
    overflow: hidden;
    opacity: .35;
    text-overflow: ellipsis;
}

.type {
    float: left;
    margin: 0 0 0 0 !important;
    background-color: rgba(255,255,255,0.05) !important;
    font-weight: 500 !important;
    padding: 8px 12px !important;
}