.Stepper {
    background-color: transparent !important;
}

.Step {

}

.StepLabel  {
    color: #fff !important;
}