.Avatar {
    text-align: center;
    margin-bottom: var(--margin-md);
}

.Avatar .ant-avatar {
    border: 6px solid var(--primary-body-background-color);
    box-sizing: content-box;
}
/* NOT WORKING BELOW */
.dark-theme .profileContainer .avatar .ant-avatar {
    border-color: var(--primary-body-background-color) !important;
}

.CardBody {
    background-color: #0E0E11 !important;
    border-radius: var(--button-radius-sm);
}

.Bio p {
    font-size: var(--font-size-sm2);
    line-height: 18px;
}

.Controls {
    border-top: 1px solid var(--primary-card-background-color);
    font-size: var(--font-size-md);
    margin: var(--margin-lg) 0;
    padding: var(--padding-lg) 0;
}

.Button {
    display: block !important;
    width: 100%;
}

.Controls > a > svg {
    margin-right: var(--margin-md);
}


.Data p {
    font-size: var(--font-size-sm2);
    margin-bottom: 0;
    text-align: center;
}


.Icons {
    display: flex;
    justify-content: center;
    margin-bottom: var(--margin-md);
}

.Icons a {
    font-size: var(--font-size-md);
    line-height: 1;
    margin: 0 var(--padding-sm);
}

.Icons a:link, .Icons a:visited {
    color: var(--secondary-link-color);
}

.Icons a:hover, .Icons a:active {
    color: var(--secondary-link-hover-color);
    animation: var(--animation-color-fast-rainbow); 
    -webkit-animation: var(--animation-color-rainbow-fast-webkit); /* Chrome and Safari */
}


.Location {
    text-align: center;
    margin-bottom: var(--margin-lg);
}

.Location span {
    color: var(--secondary-link-color);
    margin-left: var(--margin-sm);
}

.Location svg {
    color: var(--secondary-link-hover-color) !important;
}


.ProfileContainer {
    position: relative;
    top: -130px;
}

.Username {
    font-size: var(--font-size-xl);
    text-align: center;
    margin-bottom: var(--margin-md2);
}