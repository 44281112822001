.subtitle {
    color: rgba(255,255,255,0.4);
    font-size: 22px;
    font-weight: 200 !important;
    margin: 0 0 32px 0;
    text-align: center;
}

.title {
    font-size: 48px;
    font-weight: 900 !important;
    margin: 0 0 12px 0;
    text-align: center;
}

.Container {
    margin: 32px 0;
}