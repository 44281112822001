.NavAuthenticated {
    margin-left: auto;
}


.NavGuest {
    margin-left: auto;
}

.CartButton p {
    display: none;
    font-weight: 500;
}

@media (max-width: 769px) {
    .CartButton {
        background-color: rgba(255,255,255,0.2);
        border-radius: 4px;
        display: block;
        padding: 4px 8px;
        width: 100%;
    }

    .CartButton p {
        display: inline-block;
        margin-left: 8px;
    }
}

@media (min-width: 770px) {
    .CartBadge {
        position: relative;
        top: 4px;
        left: 2px;
    }
}

.CheckoutIcon {
    color: var(--primary-nav-color);
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
}

.CheckoutIcon:hover {
    color: var(--primary-nav-hover-color) !important;
}

.ButtonItem {
    margin-right: var(--margin-md);
}

.ButtonItem a.Button:link, .ButtonItem a.Button:visited {
    color: #fff !important;
    background-color: var(--primary-color) !important;
    border-radius: var(--button-radius-sm);
    transition: all .15s ease-in-out;
}

.ButtonItem a.Button:hover, .ButtonItem a.Button:active {
    color: #fff !important;
    background-color: var(--primary-color-light) !important;
    /* box-shadow: var(--shadow-purple); */
    transform: scale(1.03);
}