.entry {
    font-size: 12px;
    line-height: 1.5;
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
}

.entry span {
    font-weight: 600;
    opacity: .5;
}

.image {
    border-radius: 4px;
    margin-bottom: 24px;
    max-width: 100%;
}

.content {
    background-color: #0E0E11;
    border-radius: 4px;
    padding: 24px;
}

.content pre {
    overflow-x: hidden !important;
    text-overflow: ellipsis;
}


.SingleDefaultViewContainer {
    max-width: 100%;
    width: 100%;
    padding: 24px;
}

.service {
    margin: 0 0 24px 0;
}

.id {
    opacity: .6;
}