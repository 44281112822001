.logomark {
    width: 135px;
    position: relative;
    filter: blur(0);
    -webkit-filter: blur(0);
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
    /* top: -5px; */
}

@media (max-width: 796px) {
    .logomark {
        left: 12px;
        top: 1px;
    }
}

.navigation nav {
    background-color: var(--primary-header-background-color) !important;
    border-bottom: 4px solid #7132b1;
    box-shadow: var(--shadow) rgba(0,0,0,0.3);

    animation: var(--animation-border-color-rainbow); 
    -webkit-animation: var(--animation-border-color-rainbow-webkit);

    z-index: 2000 !important;
}

.navigation a {
    color: var(--primary-nav-color) !important;
    font-family: var(--heading-font) !important;
    font-weight: var(--font-weight-lg) !important;
    font-size: var(--font-size-md) !important;
    height: 100%;
}

.navigation a:hover, .navigation a:active {
    color: var(--primary-nav-hover-color) !important;
}