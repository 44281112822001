.AdminDashContainer {
    margin-top: 18px;
}

.AnalyticsTitleIcon {
    color:rgba(255, 255, 255, 0.15);
    font-size: var(--font-size-lg);
    margin-right: var(--margin-md);
    position: relative;
    top: 3px;
}

.card {
    margin-bottom: var(--margin-xl);
}

@media (max-width: 991px) {
    .card {
        margin-bottom: var(--margin-md);
    }

    .card:last-child {
        margin-bottom: var(--margin-xl);
    }
}

.chartCardBody {
    /* overflow: hidden; */
    position: relative;
}

.chartUsers {
    position: absolute;
    bottom: 4px;
    left: 0;
    z-index: 1;
}


.CardBody {
    background-color: #0E0E11 !important;
    border-radius: var(--button-radius-sm);
}

.CurrencyIcon {
    color: rgba(255, 255, 255, 0.466);
    font-size: var(--font-size-sm2);
    margin-left: var(--margin-sm);
    position: relative;
    top: 3px;
}

.CurrencyTitle {
    color: #fff;
    display: inline-block;
    font-weight: var(--font-weight-xxl) !important;
    font-size: var(--font-size-sm) !important;
    margin: 0 var(--margin-lg) var(--margin-sm) 0 !important;
    padding: 0;
    text-align: center;
}

.CurrencyTitleTotal {
    color: var(--color-green);
    font-weight: var(--font-weight-xxl) !important;
    font-size: var(--font-size-xl) !important;
    margin: 0 0 var(--margin-md) 0 !important;
    padding: 0;
    text-align: center;
}

.CurrencyTotals {
    position: relative;
    left: 14px;
}

.IconOnline {
    background-color: green;
    border-radius: 10px;
    border: 3px solid rgba(0,0,0,0.6);
    box-shadow: var(--shadow) var(--shadow-color-light);
    display: inline-block;
    height: 20px;
    margin-right: 8px;
    position: relative;
    top: 5px;
    width: 20px;
}

.IconOffline {
    background-color: red;
    border-radius: 10px;
    border: 3px solid rgba(0,0,0,0.6);
    box-shadow: var(--shadow) var(--shadow-color-light);
    display: inline-block;
    height: 20px;
    margin-right: 8px;
    position: relative;
    top: 5px;
    width: 20px;
}

.loadingIcon {
    position: relative;
    top: 28px;
}

.LineChart {
    position: absolute;
    bottom: 8px;
    left: 0;
}


.QueryBuilderLink {
    margin-bottom: 24px;
}

.RecentSale {
    color: green !important;
}

.RecentSaleTitle {
    color: #fff;
    text-shadow: 2px 2px 24px rgb(46, 187, 36);
    animation: crescendo 1.5s alternate infinite ease-in-out;
}
@keyframes crescendo {
    0%   {transform: scale(1); text-shadow: 2px 2px 24px rgba(46, 187, 36, .2);}
    20%   {transform: scale(1); text-shadow: 2px 2px 24px rgba(46, 187, 36, .2);}
    100% {transform: scale(1.3) translateY(-12px); text-shadow: 2px 2px 24px rgb(46, 187, 36);}
  }

.searchIcon {
    margin-right: 4px;
    opacity: .5;
}

.Section {
    margin-bottom: var(--margin-xl);
}

.Section:last-child {
    margin-bottom: 0;
}

.usersRowCard {
    margin-bottom: 8px;
}

