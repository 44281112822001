.QueryPreview {
    background-color: rgba(255,255,255,0.05);
    color: #fff;
    border-radius: var(--button-radius-sm);
    margin: var(--margin-lg) 0;
    padding: var(--padding-lg);
}

.chromaQLCode {
    font-family: 'Consolas';
    position: relative;
}

.chromaQLTitle {
    font-size: 14px;
    text-align: right;
    opacity: .6;
}

.chromaQlTitleIcon {
    opacity: .35;
    margin-right: 6px;
}

.copyButton {
    bottom: -12px;
    border: none;
    border-radius: 8px;
    background-color: transparent;
    color: rgba(255,255,255,0.2);
    font-size: 24px;
    position: absolute;
    right: 0;
}

.copyButton:hover {
    color: #fff
}