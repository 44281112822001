.container {
    background-color: #0E0E11;
    border-radius: 4px;
    padding: 48px;
    text-align: center;
    width: 100%;
}

.header {
    /* font-size: 24px; */
    font-weight: 200 !important;
    margin: 0 0 var(--padding-lg) 0;
    opacity: .7;
}

.icon {
    margin-right: 12px;
    opacity: .4;
}