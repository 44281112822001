.listItemContainer {
    background-color: #0E0E11;
    border-radius: 4px;
    margin-bottom: 4px;
    max-width: 100%;
    width: 100%;
    padding: 24px;
    position: relative;
}

.listItemContainer:last-child {
    margin-bottom: 0;
}

.affiliateId {
    font-size: 16px;
    line-height: 1;
    margin: 0 0 4px 0;
    padding: 0;
}

.affiliateId span {
    opacity: .6;
}

.amount {
    color: green;
    line-height: 1;
    margin: 0;
    top: 50%;
    padding: 0;
    position: absolute;
    right: 24px;
    text-align: right;
    transform: translateY(-50%);
}

.amount sup {
    font-size: 16px;
    opacity: .6;
    top: -11px;
}

.icon {
    font-size: 16px;
    margin-right: 8px;
    opacity: .33;
}

.id {
    color: rgba(255,255,255,0.4);
    font-size: 14px;
    line-height: 1;
    margin: 0 0 2px 0;
    padding: 0;
}

.product {
    font-weight: 600;
    line-height: 1;
    margin: 0 0 4px 0;
    padding: 0;
}

.time {
    font-size: 14px;
    line-height: 1;
    margin: 0;
    padding: 0;
}