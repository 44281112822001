.SimpleLineChartContainer {

}

.customToolTip {

}

.customToolTip h1 {
    border: none;
    font-size: 16px;
    line-height: 1;
    margin: 0 0 4px 0;
    padding: 0;
}

.customToolTip p {
    font-size: 12px;
    line-height: 1;
    margin: 0;
    padding: 0;
}