.videoembedbody {
    /* background: var(--primary-body-background-color) !important; */
    border: none !important;
    margin: 0 !important;
}

.videoembedcard {
    background: none !important;
    border: none !important;
    border-radius: var(--margin-sm) !important;
    overflow: hidden;
}

.videoembedheader {
    background-color: #0E0E11 !important;
    /* border-top-right-radius: var(--margin-md);
    border-top-left-radius: var(--margin-md); */
    color: var(--color-light) !important;
    font-weight: var(--font-weight-xl) !important;
    padding: var(--padding-md) var(--padding-md2);
}

.videoembedstream {
    /* border-bottom-right-radius: var(--margin-md);
    border-bottom-left-radius: var(--margin-md); */
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;
}

.videoembedstream iframe{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.live {
    color: #fff !important;
}
