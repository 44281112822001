.Container {
    margin-top: var(--margin-xl);
}

.header {
    margin-bottom: var(--margin-lg);
}

.Section {

}

.TransactionsMonth {
    background-color: #0E0E11;
    border-radius: 4px;
    color: var(--secondary-text-color);
    margin-bottom: var(--margin-xl);
    max-width: 100%;
    width: 100%;
    padding: 24px;
    position: relative;
}

.TransactionsMonth strong {
    color: #fff;
    font-weight: 600;
}