.header {
    font-size: var(--font-size-md);
    margin: 0 0 var(--margin-lg) 0;
}

.QueryBuilder {
    background-color: rgba(14, 14, 17, .6);
    border-radius: var(--button-radius-sm);
    padding: var(--padding-md);
}

.QueryResults {
    margin-top: 4px;
}



.querySelect {

}


