.AccordianContainer {
    cursor: pointer !important;
    pointer-events: none;
    box-shadow: var(--shadow) var(--shadow-color-xs);
    position: relative;
    width: 100%;
}

.AccordianHeading {
    flex-basis: '33.33%' !important;
    flex-shrink: 0 !important;
}

.AccordianRoot {
    border-top: 12px solid #ccc;
    animation: var(--animation-border-color-rainbow); 
    -webkit-animation: var(--animation-border-color-rainbow-webkit);
    background-color: #eee !important;
}

.AccordianSecondaryHeading {
    flex-basis: '33.33%' !important;
    flex-shrink: 0 !important;
}

.AnimationDelay1 {
    animation-delay: -2.4s !important;
}

.AnimationDelay2 {
    animation-delay: -4.8s !important;
}

.AnimationDelay3 {
    animation-delay: -7.2s !important;
}

.AnimationDelay4 {
    animation-delay: -9.6s !important;
}

.AnimationDelay5 {
    animation-delay: -11s !important;
}

.DetailsButton {
    padding: var(--padding-lg) !important;
    border-radius: var(--button-radius-sm) !important;
}

.DetailsButtonAlt {
    background-color: rgba(111, 12, 216, 0.3) !important;
    border-radius: var(--button-radius-sm) !important;
    color: #fff !important;
    padding: var(--padding-lg) !important;
}

.DetailsButtonAlt:hover {
    background-color: rgba(111, 12, 216, 0.45) !important;
}

.DetailsContainer {
    text-align: center;
}

@media (max-width: 769px) {
    .DetailsContainer {
        margin-bottom: var(--margin-xxl);
    }
}

.DetailsContent {
    text-align: center;
    font-size: var(--font-size-sm2) !important;
}

.DetailsIcon {
    animation: var(--animation-color-rainbow); 
    -webkit-animation: var(--animation-color-rainbow-webkit);
    font-size: 36px;
    margin-bottom: var(--margin-lg);
}

.DetailsHeader {
    border-bottom: 1px solid var(--muted-text-color);
    color: var(--color-dark);
    font-size: var(--font-size-lg) !important;
    font-weight: var(--font-weight-xxl) !important;
    padding-bottom: var(--padding-lg);
    text-align: center;
}

.DetailsTitle {
    color: var(--color-blue);
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-md);
    letter-spacing: -0.01em;
}

.titleContainer {
    cursor: pointer !important;
}

.title {
    color: rgba(0,0,0,0.8);
    cursor: default;
    padding: 24px 12px 0 36px;
    margin-bottom: 0;
}