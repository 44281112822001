.SocialIcon {
    color: #fff;
    font-size: 24px;
    opacity: .7;
    margin: 0 var(--margin-md) 0 0;
    transition: var(--transition-slow);
}

.SocialIcon:hover {
    opacity: 1;
    transform: scale(1.1);
}

.ShareTitle {
    font-size: var(--font-sm);
    margin-bottom: var(--margin-sm);
    opacity: .6;
}