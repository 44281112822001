.associatedCount {
    font-size: 16px;
    font-weight: 400;
    line-height: 1;
    opacity: .35;
}

.button {
    margin-top: 24px;
}

.button button {
    padding: 4px 8px !important;
}

.description {
    line-height: 1;
}

.listItemContainer {
    background-color: #0E0E11;
    border-radius: 4px;
    margin-bottom: 4px;
    max-width: 100%;
    padding: 24px;
    text-align: left;
    width: 100%;
}

.listItemContainer:last-child {
    margin-bottom: 0;
}

.name {
    background-color: transparent !important;
    clear: left;
    font-weight: 400;
    line-height: 1;
    margin: 0 0 8px 0 !important;
    padding: 0 !important;
}

.thumbnail {
    clear: both;
    float: right;
    margin-left: 12px;
    max-width: 240px;
}

@media (max-width: 769px) {
    .thumbnail {
        float: none;
        width: 100%;
        margin-left: 0;
        margin-bottom: 12px;
        max-width: 100%;
    }
}

.tokenId {
    font-size: 12px;
    font-weight: 200;
    line-height: 1;
    margin-bottom: 18px;
    max-width: 240px;
    overflow: hidden;
    opacity: .35;
    text-overflow: ellipsis;
}

.type {
    float: left;
    margin-bottom: 12px;
    background-color: rgba(255,255,255,0.2) !important;
    font-weight: 500 !important;
}