.AssetContainer {
    margin: var(--margin-xxl) 0;
}

.AssetDate {
    font-size: var(--font-sm);
    line-height: 1;
    margin: var(--margin-sm) 0 var(--margin-md) 0;
    padding: 0;
}

.AssetRight {
    position: relative;
    top: -65px;
}

.AssetType {
    background-color: var(--primary-color);
    border-radius: var(--button-radius-sm);
    color: #fff;
    display: inline-block;
    font-size: var(--font-sm);
    line-height: 1;
    margin: 0;
    padding: var(--padding-sm) var(--padding-sm);
}

.CreatedBy {
    font-size: var(--font-sm);
    line-height: 1;
    margin: 0 0 var(--margin-sm) 0;
    opacity: .6;
    padding: 0;
}


.Image {
    border-radius: var(--button-radius-lg);
    box-shadow: var(--shadow) var(--shadow-color-xs);
    max-width: 100%;
}

.Info {
    
}

.Title {
    font-size: 18px !important;
    margin: 0;
}

.UserInfo {
    border-top: 1px solid rgba(255,255,255,0.2);
    margin: var(--margin-lg) 0 var(--margin-xxl) 0;
    padding-top: var(--margin-lg);
}

.UserLink h3 {
    opacity: .7;
    transition: var(--transition-slow);
}

.UserLink:hover h3 {
    opacity: 1;
}