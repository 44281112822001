.Button {
    background-color: var(--primary-color);
    border-radius: var(--button-radius-xl);
    color: #fff !important;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-xl);
    padding: var(--padding-md) var(--padding-lg);
    transition: var(--transition-color-slow);
    white-space: nowrap
}

.Button:hover {
    background-color: var(--primary-color-dark);
}

.CardContainer {
    padding-top: var(--padding-xl);
}

/* Basically .card */
.CardContainer > div {
    background-color: #fff;
    /* background-color: var(--primary-card-background-color); */
    box-shadow: rgba(0, 0, 0, 0.6) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    margin: var(--padding-lg);
    padding: var(--padding-lg) 0 var(--padding-md) 0;
    position: relative;
    text-align: center;
}

.Description {
    color: #fff !important;
    text-align: center;
    margin-bottom: var(--margin-xxl);
}

.Header {
    color: #fff !important;
    font-size: var(--font-size-xxl);
    text-align: center;
    margin-bottom: var(--margin-md);
}

.Icon {
    color: #6d43a3;
    font-size: var(--icon-size-lg);
    position: absolute;
    top: -64px;
    transform: translateX(-50%);

}

.IconContainer {
}

.LogoContainer {
    text-align: center;
}

.LogoContainer img {
    max-width: 420px;;

}

.NewsletterContainer {
    /* background-color: #fff !important; */
    margin: var(--margin-xl) 0;
    text-align: center;
}

.NewsletterContainer > div {
    position: relative;
    transform: translateX(-50%);
    left: 50%;
} 



.NewsletterIntro {
    color: #fff;
    margin: var(--margin-md) 0 0 0;
}

.ReleasesContainer {
    text-align: center;
    margin: 18px 0 0 0 !important;
    padding: 0 0 24px 0;
}

.ReleasesIntro {
    font-size: 32px;
}

.Resources {
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgb(66, 9, 126)), url(../media/images/screenshots/Screenshot_B28.jpg);
    background-position: center center;
    background-size: cover;
    padding: var(--padding-xxl) 0;
}

/* Basically Container */
.ResourcesContainer > div {
    background-color: var(--primary-body-background-color);
}

.ResourceHeader {
    color: #333;
    padding: var(--margin-lg) 0 var(--margin-md) 0;
    margin: var(--margin-lg) 0 var(--margin-lg) 0;
}