.content {
    /* padding: var(--margin-xxl) 0 var(--margin-lg) 0; */
}

.layout {
    background-color: var(--primary-body-background-color);
}

/* Basically the main .container */
.layout > div {
    background-color: var(--primary-body-background-color) !important;
}