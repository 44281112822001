.Screenshot {
    max-width: 100%;
}

.ScreenshotContainer {
    border-radius: var(--button-radius-sm);
    overflow: hidden;
    transition: var(--transition-slow);
}

.ScreenshotContainer:hover  {
    box-shadow: var(--shadow) var(--shadow-color-xs);
    transform: scale(1.05);
    transition: var(--transition-fast);
}

.ScreenshotLink {

}