.ChromaListContainer {
    
}

.loading {
    background-color: #0E0E11;
    border-radius: 4px;    
    padding: 32px 32px;
    width: 100%;
}

.loadingIcon {
    font-size: 48px;
    margin-right: 12px;
    opacity: .4;
}

.loadingTitle {
    font-weight: 200 !important;
    margin: 0;
    opacity: .7;
    text-align: center !important;
    width: 100%;
}


.noneFoundIcon {
    margin-right: 12px;
    opacity: .4;
}