.AccordianItem, .AccordianItem button {
    background-color: transparent !important;
    padding: 0;
}

.AccordionIcon {
    font-size: 32px;
    position: absolute;
    top: 50%;
    transform: translateY(-30%);
    right: 24px;
}

.AccordionIcon path {
    color: rgba(255,255,255,0.4);
}

.Container {
    margin-top: 0;
}

.Icon {
    opacity: .4;
    margin-right: 8px;
}

.Intro {
    padding: var(--margin-xxl) 0;
}

.Intro h2 {
    font-size: 42px;
    margin: 0 0 8px 0;
    text-align: center;
}

.Intro > p {
    text-align: center;
}

.title {
    font-weight: 300;
    line-height: 1;
    margin-bottom: 24px;
}

.LinkContainer {
    text-align: center;
}

.Section {
    /* background-color: rgba(0,0,0,0.25); */
    margin-bottom: var(--margin-xxl);
}

.ViewAllButton {
    background-color: rgba(255,255,255,0.05);
    border-radius: 4px;
    color: rgba(255,255,255,0.7) !important;
    display: block;
    /* float: right; */
    margin-top: var(--margin-md) !important;
    padding: 8px 12px;
    transition: all .3s ease-out;
}

.ViewAllButton:hover {
    background-color: rgba(255,255,255,0.085);
    color: rgba(255,255,255,1) !important;
}