// Import mixins
@import 'components/mixins.scss';

////////////////////////////////////////////////////////////////////////////////////////////
// DARK THEME STYLES
[data-kit-theme='dark'] {
  .ant-card {
    color: $dark-gray-1;
    &-meta {
      &-description {
        color: $dark-gray-1;
      }
    }

    &-actions {
      background: $dark-gray-6;

      > li {
        .anticon {
          color: $dark-gray-1;
        }
      }
    }
  }
}
