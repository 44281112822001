.Container {
    margin-top: 18px;
}

@media (max-width: 769px) {
    .Container {
        margin: 0 !important;
    }
}

.usersInfo, .walletStatus {
    margin-bottom: var(--padding-sm);
}