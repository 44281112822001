.avatar {
    width: 100%;
    max-width: 100%;
}

.avatarContainer {
    border: 4px solid #fff;
    border-radius: 100px;
    display: inline-block;
    height: 200px;
    overflow: hidden;
    margin-bottom: var(--padding-lg);
    max-width: 100%;
    width: 200px;
}

.container {
    background-color: #0E0E11;
    border-radius: var(--button-radius-sm);
    margin: 0 0 var(--padding-sm) 0;
    padding: var(--padding-lg);
    text-align: center;
}

.Data {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.Data li {
    background-color: rgba(0,0,0,0.2);
    border-radius: 4px;
    color: rgba(255,255,255,0.7);
    font-size: var(--font-size-sm2);
    padding: 1.33rem 1.66rem;
    margin-bottom: var(--margin-sm);
}

.Data li strong {
    color: #fff;
    margin-left: 4px;
}

.joinedIcon {
    margin-right: 8px;
    opacity: .4;
}

.membersince {
    font-size: 16px;
    margin: 0;
}

.seedcode {
    font-size: 16px;
    margin: 0;
}

.userid {
    font-size: 16px;
    opacity: .5;
}

.username {
    font-size: var(--font-size-xl) !important;
    margin: 0;
}