footer {
    background-color: var(--primary-header-background-color);
    color: var(--muted-text-color);
    font-size: var(--font-size-md);
    overflow: hidden;
    padding: var(--padding-lg);
    text-align: center;
}

footer > .container {
    background-color: var(--primary-header-background-color);
    margin-bottom: 0;
    padding: 0;
}
@media (min-width: 992px) {
    .FooterLogo {
        text-align: right;
    }
}

.FooterLogo a img {
    width: 136px;
}

.Icons {
    display: flex;
    justify-content: left;
    position: relative;
    top: 4px;
}

.Icons a {
    font-size: var(--font-size-lg);
    line-height: 1;
    margin: 0 var(--padding-md);
}

.Icons a:last-child {
    margin-right: 0;
}

.Icons a:link, .Icons a:visited {
    color: var(--secondary-link-color);
}

.Icons a:hover, .Icons a:active {
    color: var(--secondary-link-hover-color);
    animation: var(--animation-color-rainbow-fast); 
    -webkit-animation: var(--animation-color-rainbow-fast-webkit); /* Chrome and Safari */
}

footer > .container > .row > .col-sm:first-child {
    text-align: left;
}

footer > .container > .row > .col-sm:last-child {
    text-align: right;
}
@media (max-width: 991.98px) {
    .Icons {
        justify-content: center;
        margin-bottom: var(--margin-md);
    }
    
    footer > .container > .row > .col-sm:last-child {
        text-align: center;
    }
}