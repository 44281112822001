.assetThumb {
    max-width: 100%;
    object-fit: cover;
}

div.body {
    background: #fff !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    padding: 8% !important;
}

.button {
    margin-top: 8px;
    width: 100%;
}

@media (min-width: 577px) {
    .button {
        width: 90%;
        border-radius: 4px;
        bottom: 5%;
        left: 5%;
        position: absolute;
    }
}


.Card {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 20px 30px;
    overflow: hidden;
}

@media (min-width: 577px) {
    .Card {
        height: 100%;
    }
}

.chromaNfts {
    margin-top: 48px !important;
}

.container {
    padding: 0 !important;
}

.description {
    font-size: 14px;
    line-height: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ethAddress {

}

.emptyHeader {
    font-size: 18px;
    font-weight: 300;
    margin: 24px 0;
    text-align: center;
}

.FormLabel {
    color: #fff !important;
    font-size: var(--font-size-md);
    margin-bottom: var(--margin-md);
}

.linkContainer {
    background-color: #0E0E11;
    border-radius: 4px;
    text-align: center;
    padding: 18px 18px 42px 18px;
}

.linkContainer h3 {
    margin-bottom: 8px;
}

.linkContainer p {
    font-size: 16px;
    line-height: 22px;
    margin: 0 auto;
}

@media (min-width: 577px) {
    .linkContainer p {
        max-width: 70%;
    }
}

.linkFinePrint {
    color: rgba(255,255,255, 0.2);
    font-size: 12px !important;
    margin-bottom: 24px !important;
}

.unlinkContainer {
    text-align: left;
    padding: 18px;
}

.unlinkContainer p {
    text-align: left;
    line-height: 1;
    max-width: 100%;
}

.metamaskLogo {
    margin: 0 auto;
    max-width: 240px;
    width: 100%;
}

.refreshIcon {
    color: rgba(255,255,255,0.4);
    margin-right: 8px;
}

.refreshWallet {
    background-color: transparent !important;
    font-size: 16px;
    font-weight: 800 !important;
    transition: all .4s ease-out !important;
    opacity: .7;
}

.refreshWallet:hover {
    opacity: 1;
    transition: all .4s ease-out;
}

.thumbContainer {
    align-items: center;
    display: flex;
    max-height: 92px;
    justify-content: center;
    overflow: hidden;
}

.thumbContainer img {
    /* flex: none; */
    width: 100%;
}

.poapThumbContainer {
    background-color: #fff;
    padding: 12px;
}

.poapThumbContainer img {
    /* flex: none; */
    width: 100%;
}

.unlinkButton {
    float: right;
}

.linkedIcon {
    color: rgba(0,0,0,0.2);
    margin-right: 4px;
    position: relative;
    top: -1px;
}

.linkedProduct {
    color: rgba(51, 51, 51, 0.69);
    font-size: 12px !important;
    font-weight: 500;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.name {
    color: #333;
    font-size: 18px;
    line-height: 20px;
    margin: 0;
    padding: 0;
}
