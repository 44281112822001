.Button {
    margin-top: 12px;
}

@media (max-width: 769px) {
    .Button {
        width: 100%;
    }
}
.entry {
    clear: left;
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0;
}

.entryMeta {
    border: 1px solid rgba(255,255,255,0.1);
    border-radius: 8px;
    clear: both;
    margin: 12px 0 0 0 !important;
    max-width: 100%;
    padding: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.entry span {
    font-weight: 600;
    opacity: .5;
}

.listItemContainer {
    background-color: #0E0E11;
    border-radius: 4px;
    margin-bottom: 4px;
    max-width: 100%;
    width: 100%;
    padding: 24px;
}

.listItemContainer:last-child {
    margin-bottom: 0;
}

.name {
    background-color: transparent !important;
    margin: 0 0 8px 0 !important;
    padding: 0 !important;
}

.thumbnail {
    clear: both;
    float: right;
    margin-left: 12px;
    max-width: 240px;
}

@media (max-width: 769px) {
    .thumbnail {
        float: none;
        width: 100%;
        margin-left: 0;
        margin-bottom: 12px;
        max-width: 100%;
    }
}

.type {
    float: left;
    margin-bottom: 12px;
    background-color: rgba(255,255,255,0.2) !important;
    font-weight: 500 !important;
}