.TabContainer {
    background-color: var(--primary-color) !important;
    flex-grow: 1;
    margin-bottom: var(--margin-lg);
}

.Tabs {
    padding: 0 !important;
}

.Tab {
    color: rgba(255,255,255,1) !important;
}

.Tab.Mui-selected {
    color: #fff !important;
}