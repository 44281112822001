.avatar {
    border: 4px solid rgba(255,255,255,0.15);
    border-radius: 36px;
    height: 72px;
    float: right;
    overflow: hidden;
    width: 72px;
}

.avatar img {
    max-width: 100%;
}

.date {
    font-size: 14px;
    line-height: 1;
    margin: 0 0 4px 0;
    padding: 0;
}

.description {
    line-height: 1;
}

.ethId, .email { 
    font-size: 14px;
    line-height: 1;
    margin: 0 0 4px 0;
    padding: 0;
    display: inline;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
}

.icon {
    font-size: 16px;
    margin-right: 8px;
    opacity: .33;
}

.id {
    color:rgba(255,255,255,0.4);
    font-size: 14px;
    font-weight: 400;
    margin: 0 0 12px 0;
}

.infoRow > p {
    float: left;
    margin-right: 12px;
}

.link:link h2, .link:visited h2 {
    color: #6f0cd8 !important;
    display: inline-block;
    opacity: .5;
}

.link:hover h2, .link:active h2 {
    opacity: .9;
}

.listItemContainer {
    background-color: #0E0E11;
    border-radius: 4px;
    margin-bottom: 8px;
    padding: 24px;
    width: 100%;
}

.listItemContainer:last-child {
    margin-bottom: 0;
}

.minutes {
    font-size: 14px;
    line-height: 1;
    margin: 0 0 4px 0;
    padding: 0;
}

.name {
    background-color: transparent !important;
    display: inline-block;
    font-weight: 600;
    font-size: 24px !important;
    margin: 0 0 4px 0 !important;
    padding: 0 !important;
}