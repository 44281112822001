.QuerySelector {
    /* border: 1px solid white; */
    min-height: 39px;
    position: relative;
    /* top: 8px; */
}

.queries {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    grid-gap: 10px;
}

.query {
    /* margin-bottom: 8px; */
}


.sorting {
    
}

.sortButton {
    background-color: transparent;
    border: none;
    float: right;
    margin-left: 2px;
    position: relative;
    top: 5px;
}

.sortIcon {
    font-size: 14px !important;
    margin-right: 8px;
    opacity: .4;
    transition: all .3s ease-out;
}

.sortIcon:hover {
    opacity: 1;
}

.sortSelect {
    float: right;
    width: 120px !important;
}

.toggleButton {
    background-color: transparent;
    border: none;
    float: left;
    margin: 0 8px 0 2px;
    top: 5px;
    position: relative;
}

.toggleIcon {
    font-size: 24px;
    left: -2px;
    opacity: .4;
    position: relative;
    top: 1px;
    transition: all .3s ease-out;
}

.toggleIconThin {
    margin: 0 1px 0 5px;
}

.toggleIcon:hover {
    opacity: 1;
}

