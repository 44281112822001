.AdminDashContainer {
    margin-top: 36px;
}

.CurrencyIcon {
    color: rgba(255, 255, 255, 0.466);
    font-size: var(--font-size-sm2);
    margin-left: var(--margin-sm);
    position: relative;
    top: 3px;
}

.CurrencyTitle {
    color: #fff;
    display: inline-block;
    font-weight: var(--font-weight-xxl) !important;
    font-size: var(--font-size-sm) !important;
    margin: 0 var(--margin-lg) var(--margin-sm) 0 !important;
    padding: 0;
    text-align: center;
}

.CurrencyTitleTotal {
    color: var(--color-green);
    font-weight: var(--font-weight-xxl) !important;
    font-size: var(--font-size-xl) !important;
    margin: 0 0 var(--margin-md) 0 !important;
    padding: 0;
    text-align: center;
}

.CurrencyTotals {
    position: relative;
    left: 14px;
}

.Section {
    margin-bottom: var(--margin-xl);
}

.Section:last-child {
    margin-bottom: 0;
}

.Section h2 {
    font-size: var(--font-size-md);
    margin: 0 0 var(--margin-lg) 0;
}

.AnalyticsTitleIcon {
    color:rgba(255, 255, 255, 0.15);
    font-size: var(--font-size-lg);
    margin-right: var(--margin-md);
    position: relative;
    top: 3px;
}