.Description {
    color: #fff !important;
    text-align: center;
    margin-bottom: var(--margin-xxl);
}

.Header {
    color: #fff !important;
    font-size: var(--font-size-xxl);
    text-align: center;
    margin-bottom: var(--margin-md);
}


.Map {
    border-radius: var(--button-radius-xl);
    box-shadow: var(--shadow-thick2) var(--shadow-color-light);
    max-width: 100%;
}

.WorldsContainer {
    margin: 0;
}


.Worlds {
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgb(66, 9, 126)), url(../media/images/screenshots/Screenshot_A15.jpg);
    background-position: center center;
    background-size: cover;
    padding: var(--padding-xxl) 0;
}