.address {
    background-color: rgba(255,255,255,0.05);
    border-radius: 8px;
    line-height: 1;
    margin: 12px 0 0 0;
    max-width: 100%;
    padding: 4px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.container {
    background-color: #0E0E11;
    border-radius: var(--button-radius-sm);
    padding: var(--padding-md);
    position: relative;
    text-align: center;
}

.icon {
    color: green;
    position: absolute;
    left: 50%;
    transform: translateX(-55px);
    top: 12px;
}

.linkWalletButton {
    margin-left: 12px;
}

.metamaskLogo {
    width: 70px;
}

