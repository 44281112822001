/* Import Fonts */
/* @import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;600;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;900&display=swap');

/* Import Icons */
@import url('https://cdn.rawgit.com/luizbills/feather-icon-font/v4.7.0/dist/feather.css');

/* Light */
.light-theme {
    /* Colors */
    --primary-header-background-color: #0E0E11;
    --primary-header-border-color:  rgba(0,0,0,0.2);
    --primary-body-background-color: #fff;
    --primary-card-background-color: #0E0E11;
    --primary-card-background-color-light: #d9e4ec;
    --primary-text-color: #333;
    --secondary-text-color: rgb(75, 75, 75);
    --disabled-color: #6c757d;
    
    --primary-link-color:#6e0cd8;
    --primary-link-hover-color:#7f11f5;
    --secondary-link-color: #757575;
    --secondary-link-hover-color: #949494;
    
    --tab-active-color: #fff;
    --tab-link-color: rgba(255,255,255,0.5);
    --tab-link-hover-color: #fff;

    --table-header-color: #a5aeb4;
    --table-header-text-color: #333;
    --table-row-color: var(--primary-card-background-color);
    --table-row-hover-color: #333;
    --table-row-border-color: var(--primary-body-background-color);

    --shadow-color: var(--shadow-color-light);
}

/* Light */
.dark-theme {
    /* Colors */
    --primary-header-background-color: #0E0E11;
    --primary-header-border-color:  rgba(255,255,255,0.2);
    --primary-body-background-color: rgb(28, 28, 33);
    --primary-body-background-color-dark: rgb(20, 20, 24);
    --primary-card-background-color: rgb(49, 49, 58);
    --primary-card-background-color-light: #42424b;
    --primary-card-background-color-extra-light: #ececf8;
    /* --primary-text-color: rgb(191, 191, 191); */
    --primary-text-color: #fff;
    --primary-text-dark-color: #333;
    --secondary-text-color: rgb(191, 191, 191, 1);
    --muted-text-color: #c4c4c4;
    --disabled-color: #6c757d;

    --primary-link-color:hsl(269, 89%, 65%) !important;
    --primary-link-hover-color:hsl(269, 83%, 50%);
    --primary-link-color-bright:hsl(269, 100%, 70%) !important;
    --primary-link-hover-color-bright:hsl(269, 100%, 76%);
    --secondary-link-color: #757575;
    --secondary-link-hover-color: #949494;

    --tab-active-color: #fff;
    --tab-link-color: rgba(255,255,255,0.5);
    --tab-link-hover-color: #fff;

    --table-header-color: var(--primary-card-background-color);
    --table-header-text-color: #fff;
    --table-row-color: var(--primary-card-background-color-light);
    --table-row-hover-color: #333;
    --table-row-border-color: var(--primary-body-background-color);

    --shadow-color: var(--shadow-color-dark);
}



/* Utilities */
:root {
    /* Colors */
    --primary-color: var(--color-purple);
    --primary-color-light: var(--color-light-purple);
    --primary-color-dark: var(--color-dark-purple);

    --primary-nav-color: rgba(255, 255, 255, 0.637);
    --primary-nav-hover-color: var(--primary-color);
    /* --primary-nav-hover-color: rgba(255, 255, 255, 0.9); */

    --primary-text-color-dark: rgb(191, 191, 191);
    
    --color-warning: #ED83A9;
    --color-error: #cc4d37;
    --color-blue: #229bcf;
    --color-blue-light: #85DAFF;
    --color-blue-dark: #176b8f;
    --color-green: #3c8027;
    --color-green-light: #4ba031;
    --color-green-dark: #2b5d1c;
    --color-purple: #6f0cd8;
    --color-light-purple: #8131d6;
    --color-dark-purple: #450985;
    --color-white: #fff;

    --color-heat: #af1f12;
    --color-heat-glow: #af1f12b9;
    --color-mass: #42b41f;
    --color-mass-glow: #42b41fb9;
    --color-flow: #3ab2e6;
    --color-flow-glow: #3ab2e6b9;

    /* --color-bitcoin: #EFB678; */
    --color-bitcoin: #F7931A;
    --color-ltc: #00aeff;
    --color-eth: #c99d66;
    --color-xmr: #ff6600;

    /* Fonts */
    --heading-font: 'Inter', sans-serif !important;
    --body-font: 'Roboto', sans-serif;   
    --body-line-height: 32px;


    /* Sizes */
    --font-size-xs: 10px;
    --font-size-sm: 12px;
    --font-size-sm2: 14px;
    --font-size-sm2: 16px;
    --font-size-md: 18px;
    --font-size-md: 20px;
    --font-size-lg: 24px;
    --font-size-xl: 32px;
    --font-size-xxl: 56px;

    --font-weight-sm: 100;
    --font-weight-md: 400;
    --font-weight-md-2: 500;
    --font-weight-lg: 600;
    --font-weight-xl: 700;
    --font-weight-xxl: 800;

    --icon-size-lg: 128px;

    --margin-xs: 2px;
    --margin-sm: 4px;
    --margin-sm2: 8px;
    --margin-md: 12px;
    --margin-md2: 18px;
    --margin-lg: 24px;
    --margin-xl: 48px;
    --margin-xxl: 64px;
    --margin-xxxl: 128px;

    --padding-xs: var(--margin-xs);
    --padding-sm: var(--margin-sm);
    --padding-sm2: var(--margin-sm2);
    --padding-md: var(--margin-md);
    --padding-md2: var(--margin-md2);
    --padding-lg: var(--margin-lg);
    --padding-xl: var(--margin-xl);
    --padding-xxl: var(--margin-xxl);
    --padding-xxxl: var(--margin-xxxl);

    /* Colors */
    --color-light: #ccc;
    --color-dark: #333;

    /* Shadows */
    --shadow: 2px 2px 12px 4px;
    --shadow-medium: 4px 4px 18px 8px;
    --shadow-thick: 2px 2px 24px 16px;
    --shadow-thick2: 4px 4px 28px 24px;
    --shadow-color-bright: rgba(255,255,255,0.3);
    --shadow-color-xs: rgba(0,0,0,0.15);
    --shadow-color-light: rgba(0,0,0,0.3);
    --shadow-color-dark: rgba(0,0,0,0.4);
    --shadow-color-black: rgba(0,0,0,0.7);

    --shadow-purple: 0 0 18px 0px rgba(201, 188, 212, 0.377);

    --shadow-glow-colors: 0 0 32px rgba(224, 222, 134, .7),
        -10px 0 42px rgba(255, 0, 255, 0.35),
        -10px 0 42px rgba(224, 222, 134, 0.4),
        10px 0 42px rgba(0, 255, 255, 0.35);

    --shadow-glow-colors-small: 0 0 22px rgba(224, 222, 134, .6),
        -10px 0 32px rgba(255, 0, 255, 0.25),
        -10px 0 32px rgba(224, 222, 134, 0.3),
        10px 0 32px rgba(0, 255, 255, 0.25);

    /* Border Radius */
    --button-radius-sm: 4px;
    --button-radius-md: 8px;
    --button-radius-lg: 12px;
    --button-radius-xl: 24px;
    --button-radius-xxl: 32px;

    /* Transitions */
    --transition-color-slow: 
        color .15s ease-in-out,
        background-color .15s ease-in-out,
        border-color .15s ease-in-out;
    --transition-color: color .25s ease-out;
    --transition-instant: all .07s ease-in-out;
    --transition-fast: all .15s ease-in-out;
    --transition-slow: all .25s ease-in-out;

    /* Animations */
    /* you could also use milliseconds (ms) or something like 2.5s */

    --animation-border-color-rainbow: animation-border-color-rainbow 8s ease-in-out 1s infinite;
    --animation-border-color-rainbow-webkit: animation-border-color-rainbow 8s ease-in-out 0s infinite alternate;

    --animation-background-color-rainbow: animation-background-color-rainbow 8s ease-in-out 1s infinite;
    --animation-background-color-rainbow-webkit: animation-background-color-rainbow 8s ease-in-out 0s infinite alternate;

    --animation-color-rainbow: animation-color-rainbow 16s ease-in-out 1s infinite;
    --animation-color-rainbow-webkit: animation-color-rainbow 16s ease-in-out 0s infinite alternate;

    --animation-color-rainbow-fast: animation-color-rainbow 2s ease-in-out 2s infinite;
    --animation-color-rainbow-fast-webkit: animation-color-rainbow 2s ease-in-out 0s infinite alternate;

    --animation-color-rainbow-slow: animation-color-rainbow 24s ease-in-out 24s infinite;
    --animation-color-rainbow-slow-webkit: animation-color-rainbow 24s ease-in-out 0s infinite alternate;
}

/* Animations */
@keyframes animation-color-rainbow
{
    0%   {color: #ADDD9E; }
    12%  {color: #86EFCA; }
    24%  {color:#7CE5E8; }
    36% {color: #85DAFF; }
    48%   {color: #D2A7FD; }
    56%   {color:#ED83A9; }
    72%   {color: #EFB678; }
    100%   {color: #E7DC8B; }
}
/* Safari and Chrome - necessary duplicate */
@-webkit-keyframes animation-color-rainbow 
{
    0%   {color: #ADDD9E; }
    12%  {color: #86EFCA; }
    24%  {color:#7CE5E8; }
    36% {color: #85DAFF; }
    48%   {color: #D2A7FD; }
    56%   {color:#ED83A9; }
    72%   {color: #EFB678; }
    100%   {color: #E7DC8B; }
}

@keyframes animation-border-color-rainbow
{
    0%   {border-color: #ADDD9E; }
    12%  {border-color: #86EFCA; }
    24%  {border-color:#7CE5E8; }
    36% {border-color: #85DAFF; }
    48%   {border-color: #D2A7FD; }
    56%   {border-color:#ED83A9; }
    72%   {border-color: #EFB678; }
    100%   {border-color: #E7DC8B; }
}
/* Safari and Chrome - necessary duplicate */
@-webkit-keyframes animation-border-color-rainbow 
{
    0%   {border-color: #ADDD9E; }
    12%  {border-color: #86EFCA; }
    24%  {border-color:#7CE5E8; }
    36% {border-color: #85DAFF; }
    48%   {border-color: #D2A7FD; }
    56%   {border-color:#ED83A9; }
    72%   {border-color: #EFB678; }
    100%   {border-color: #E7DC8B; }
}

@keyframes animation-background-color-rainbow
{
    0%   {background-color: #ADDD9E; }
    12%  {background-color: #86EFCA; }
    24%  {background-color:#7CE5E8; }
    36% {background-color: #85DAFF; }
    48%   {background-color: #D2A7FD; }
    56%   {background-color:#ED83A9; }
    72%   {background-color: #EFB678; }
    100%   {background-color: #E7DC8B; }
}
/* Safari and Chrome - necessary duplicate */
@-webkit-keyframes animation-background-color-rainbow 
{
    0%   {background-color: #ADDD9E; }
    12%  {background-color: #86EFCA; }
    24%  {background-color:#7CE5E8; }
    36% {background-color: #85DAFF; }
    48%   {background-color: #D2A7FD; }
    56%   {background-color:#ED83A9; }
    72%   {background-color: #EFB678; }
    100%   {background-color: #E7DC8B; }
}




/* General Overrides */
#root {
    padding-top: 78px;
}


@media (max-width: 991.98px) {
    #root {
        /* Make up for fixed nav */
        padding-top: 70px;
    }
}

body {
    background-color: rgb(14, 14, 17);
    cursor: default;
    font-family: var(--body-font);
    text-rendering: optimizeLegibility;
}

h1 {
    border-bottom: 1px solid var(--primary-header-border-color);
    color: var(--primary-text-color);
    font-family: var(--heading-font);
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-lg);
    line-height: 1;
    margin: 0 0 var(--margin-xl) 0;
    padding: 0 0 var(--margin-xl) 0;
}

h2 {
    color: var(--primary-text-color);
    font-family: var(--heading-font) !important;
    font-size: var(--font-size-xl);
    font-weight: var(--font-weight-xl);
    line-height: 1;
    margin: 0 0 var(--margin-xl) 0;
}

h3, h4 {
    color: var(--primary-text-color);
    font-family: var(--heading-font) !important;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-xl);
    letter-spacing: -0.035em;
    line-height: 1;
    margin: 0 0 var(--margin-lg) 0;
    padding: 0;
}

hr {
    border-width: 0;
}

p {
    color: var(--secondary-text-color);
    font-family: var(--body-font);
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-md);
    line-height: var(--body-line-height);
    margin: 0 0 var(--margin-lg) 0;
    padding: 0;
}

p:last-child {
    margin-bottom: 0;
}

p.small {
    font-family: var(--body-font);
    font-size: var(--font-size-sm2);
    font-weight: var(--font-weight-md);

}

p:last-child {
    margin-bottom: 0;
}


a:link, a:visited {
    color: var(--primary-link-color);
    text-decoration: none;
}

a:hover, a:active {
    color: var(--primary-link-hover-color);
}



/* Forms */
button.primary {
    background-color: var(--primary-color-light);
    border-radius: var(--button-radius-sm);
    border: none;
    color: #fff;
    padding: var(--padding-md) var(--padding-md2);
    transition: var(--transition-color-slow);
}

button.primary:hover {
    background-color: var(--primary-color);
}

input, textarea {
    font-size: var(--font-size-md);
}







/* BootStrap Overrides */
.card {
    background-color: var(--primary-card-background-color);
}

.container {
    background-color: transparent !important;
    margin-bottom: 0 !important;
}

.dark-theme .card {
    border: none;
}

/* BootStrap Dropdown Overrides */
.dropdown .dropdown-menu, .btn-group .dropdown-menu {
    background-color: var(--primary-header-background-color);
}

.dropdown-item {
    width: auto !important;
}

.fixed-top {
    z-index: 5 !important;
}

.main.navbar .navbar-brand {
    position: absolute;
    top: -5px;
}

.main.navbar .navbar-brand > a {
    display: inline-block;
    position: relative;
    /* transform: translateX(-50%); */
    z-index: 10;
}

@media (min-width: 1200px) {
    .main.navbar .navbar-brand > a {
        left: 0 !important;
    }
}

@media (min-width: 992px) {
    .main.navbar .navbar-brand > a {
        left: -19px;
    }
}

@media (max-width: 992px) {
    .main.navbar .navbar-brand > a {
        top: -4px;
        left: -5px;
    }
}

.main.navbar a.nav-link {
    align-items: center;
    color: var(--primary-nav-color);
    display: flex;
    font-family: var(--header-font);
    font-weight: var(--font-size-sm);
    font-size: var(--font-size-md);
    height: 100%;
    padding: var(--padding-sm) var(--padding-md);
}

.main.navbar a.nav-link:hover, .main.navbar a.nav-link:active, .main.navbar a.nav-link:focus {
    color: var(--primary-nav-hover-color);
}

.main.navbar .nav-item:last-child a.nav-link {
    position: relative;
    padding-right: 32px !important;
}

.main.navbar .dropdown-menu {
    width: 177px;
}

@media (max-width: 769px) {
    .main.navbar .dropdown-menu {
        width: 100%;
    }
}

/* .main.navbar .dropdown .dropdown-toggle::after, .btn-group .dropdown-toggle::after {
    transform: translate(50px, 0px) !important;
} */

.main.navbar .navbar-toggler {
    color: var(--primary-nav-color) !important;
    float: right;
    padding-right: 0;
}

.main.navbar svg {
    margin-right: var(--margin-md);
    /* margin-right: 0; */
}


@media (max-width: 991.98px) {
    .main.navbar .navbar-collapse {
        margin-top: 52px;
    }
    
    .main.navbar .navbar-collapse .nav-link {
        /* justify-content: center; */
    }
}




/* Card */
.card, .card-body {
    border-radius: var(--button-radius-sm) !important;
}





/* Buttons */
.btn.btn-primary {
    background-color: var(--primary-link-color);
    border: none;
}

.btn.btn-primary:hover, .btn.btn-primary:active:focus, .btn.btn-primary:focus {
    background-color: var(--primary-link-hover-color) !important;
    border: none !important;
    outline: none !important;
    box-shadow: none;
}


/* react-toast-notification overrides */
.react-toast-notifications__container {
    overflow: hidden !important;
}

.react-toast-notifications__content {
    
}

.react-toast-notifications__toast {
    background-color: rgb(49, 49, 58) !important;
    width: 420px !important;
}

.react-toast-notifications__toast__content {
    color: #fff;
    line-height: 23px !important;
    text-align: center;
    padding: 18px !important;
}

.react-toast-notifications__toast__dismiss-button > svg {
    position: relative;
    top: 0px !important;
}

.react-toast-notifications__toast__icon-wrapper > svg {
    position: relative;
    top: 0px !important;
}

.react-toast-notifications__toast--info .react-toast-notifications__toast__icon-wrapper {
    background-color: #6f0cd8;
}





/* MaterialUI Overrides*/

/* Tooltip */
.MuiTooltip-popper {
    top: 8px !important;
}

/* Buttons */
.MuiButton-containedPrimary {
    background-color: var(--primary-color) !important;
}
.MuiButton-containedPrimary:hover {
    background-color: var(--primary-color-dark) !important;
}

.MuiButton-outlinedPrimary {
    color: var(--primary-color);
    border-color: var(--primary-color) !important;
}

.MuiButton-outlinedPrimary:hover {
    background-color: var(--primary-color) !important;
    color: #fff !important;
}



/* MUI Tables */
.MuiTableHead, .MuiTableCell-root {
    border-bottom-color: var(--table-row-border-color) !important;
}

.MuiTablePagination-root {
    background-color: rgb(42, 42, 49) !important;
    border-top: 1px solid var(--primary-body-background-color);
    border-bottom-left-radius: var(--button-radius-md);
    border-bottom-right-radius: var(--button-radius-md);
}

/* MUI Tabs */
.MuiTab-textColorPrimary {
    color: var(--tab-link-color) !important;
}
.MuiTab-textColorPrimary:hover {
    color: var(--tab-link-hover-color) !important;
}

.MuiTab-textColorPrimary.Mui-selected {
    color: var(--tab-active-color) !important;
}

.MuiTab-root .MuiTab-wrapper {
    transition: var(--transition-color-slow);
}



/* MUI Accordian */

.MuiCollapse-container {
    background-color: #fff;
    /* padding: var(--padding-lg); */
}

.MuiAccordion-root {
    margin-bottom: 1px;
}

.MuiAccordionDetails-root {
    display: block !important;
    padding: var(--padding-xl) !important;
}

.MuiAccordionDetails-root p {
    color: var(--primary-text-dark-color);
}

.MuiAccordionSummary-root {}

.MuiAccordionSummary-content p {
    color: var(--primary-text-dark-color) !important;
    font-size: var(--font-size-sm2) !important;
    font-weight: var(--font-weight-lg);
    text-transform: uppercase;
}

.MuiAccordion-rounded {
    border-radius: var(--button-radius-sm) !important;
}

.MuiAccordion-root.MuiAccordion-rounded {
    margin-bottom: var(--margin-md);
}

.MuiAccordion-root.MuiAccordion-rounded:last-child {
    margin-bottom: 0;
}



/* MUI Select */
.MuiFilledInput-underline:after {
    border-color: var(--primary-color) !important;
}

.MuiFormLabel-root {

}

.MuiFormLabel-root.Mui-focused {
    color: var(--primary-color) !important;
}


/* MUI Stepper */
.MuiStepLabel-labelContainer .MuiStepLabel-label {
    color: #fff;
}

.MuiStepLabel-completed {
    color: rgba(255,255,255,0.5) !important;
}

.MuiStepLabel-labelContainer .MuiStepLabel-label.MuiStepLabel-active {
    color: #fff;
}

.MuiStepIcon-root {
    color: rgba(255,255,255,0.3) !important;
}

.MuiStepIcon-active {
    color: var(--color-green) !important;
}



/* MUI Linear Progress */
.MuiLinearProgress-colorPrimary {
    background-color: rgba(255,255,255,0.2) !important;
}
.MuiLinearProgress-barColorPrimary {
    background-color: var(--primary-color-light);
}


/* MUI Circular Progress */
.MuiCircularProgress-circle, .MuiCircularProgress-circleDisableShrink {
    color: var(--primary-color-light);
}

.MuiCircularProgress-root.MuiCircularProgress-colorSecondary svg circle {
    stroke: var(--color-green) !important;
}


/* react-colorful picker */
.react-colorful {
    width: 100% !important;
}
.react-colorful__saturation {
    border-radius: var(--button-radius-sm) var(--button-radius-sm) 0 0 !important;
}
.react-colorful__last-control {
    border-radius: 0 0 var(--button-radius-sm) var(--button-radius-sm) !important;
}


/* react-slick carousel */
.slick-slider {
    line-height: 0 !important;
}

.slick-arrow {
    z-index: 1000;
}
.slick-arrow::before {
    font-family: normal normal normal 14px/1 FontAwesome;
}
.slick-arrow.slick-prev {
    left: var(--margin-xxl);
}
.slick-arrow.slick-prev::before {
    content: "\f053";
}

.slick-arrow.slick-next {
    right: var(--margin-xxl);
}
.slick-arrow.slick-next::before {
    content: "\f054";
}

.slick-dots {
    bottom: var(--margin-sm);
    display: block;
    z-index: 1000;
}

.slick-dots > li {
    width: auto;
}

.slick-dots > li button {
    background-color: rgba(255,255,255,0.25) !important;
    border-radius: 2px;
    height: 3px !important;
    padding: 0;
    width: 36px !important;
}

.slick-dots > li button::before {
    content: "" !important;
}

.slick-dots .slick-active button {
    background-color: rgba(255,255,255,0.8) !important;
}






/* recharts */
.recharts-curve.recharts-tooltip-cursor {
    stroke: rgba(0,0,0,0);
}


/* React Accordian */
.accordion-button::after {
    display: none;
}



/* Newsletter */
.klaviyo-form p {
    color: #fff !important;
}