.ChromaMessagesContainer {
    position: fixed;
    width: 100%;
    z-index: 1000;
}

@media (min-width: 575px) {
    .ChromaMessagesContainer {
        top: 72px;
    }
}

@media (max-width: 575px) {
    .ChromaMessagesContainer {
        top: 69px;
    }
}

@media (min-width: 991px) {
    .ChromaMessagesContainer {
        top: 82px;
    }
}

.ChromaMessages {
    /* background-color: rgba(255,255,255,0.2); */
    padding: var(--padding-md);
    position: absolute;
    width: 100%;
}



.Message {
    margin-bottom: var(--margin-sm);
}

.Message p {
    color: #fff;
    font-size: var(--font-size-sm2);
    line-height: 1;
    margin: 0;
}