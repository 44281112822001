.ProfileOverview {

}

.Amount {
    background-color: var(--primary-header-background-color) !important;
    border-radius: 64px;
    display: inline-block;
    font-weight: 800 !important;
    /* height: 64px; */
    line-height: 60px;
    margin: var(--margin-md) 0 0 0;
    padding: var(--padding-md) var(--padding-lg);
    /* width: 64px; */
}

.Card {
    /* background-color: var(--primary-card-background-color-extra-light) !important; */
    /* background-color: var(--primary-body-background-color-dark) !important; */
    /* border: 1px solid rgba(204, 204, 204, 0.157) !important; */
    background-image: linear-gradient(var(--primary-body-background-color), var(--primary-card-background-color)) !important;
}

.Container {
    padding: 32px;
    text-align: center;
}

.Heat .Icons {
    color: var(--color-heat);
    filter:drop-shadow(2px 2px 10px var(--color-heat-glow));
}
.Mass .Icons {
    color: var(--color-mass);
    filter:drop-shadow(2px 2px 10px var(--color-mass-glow));
}
.Flow .Icons {
    color: var(--color-flow);
    filter:drop-shadow(2px 2px 10px var(--color-flow-glow));
}

.Icons {
    font-size: 64px;
}

.Title {
    font-weight: var(--font-weight-lg) !important;
    font-size: var(--font-size-md);
    margin: var(--margin-md) 0;
}