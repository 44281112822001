.OverviewDashContainer {
    
}

.Section {
    margin-bottom: var(--margin-lg);
}

.Section:last-child {
    margin-bottom: 0;
}

.Section h2 {
    background-color: var(--primary-card-background-color);
    border-top-left-radius: var(--button-radius-sm);
    border-top-right-radius: var(--button-radius-sm);
    font-size: var(--font-size-sm3);
    margin: 0 0 1px 0;
    padding: var(--padding-lg) var(--padding-lg);
}