.AltImage {
    box-shadow: var(--shadow-thick) var(--shadow-color-black);
    max-width: 100%;
    filter: blur(0);
    -webkit-filter: blur(0);
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
}

.AltImageNoShadow {
    max-width: 100%;
    filter: blur(0);
    -webkit-filter: blur(0);
    transform: translateZ(0);
    -webkit-transform: translateZ(0);
}

.Button {
    background-color: var(--primary-color);
    border: none;
    border-radius: var(--button-radius-sm);
    /* box-shadow: var(--shadow) var(--shadow-color-light) !important; */
    color: #fff;
    font-size: var(--font-size-lg);
    font-weight: var(--font-weight-md);
    line-height: 1;
    padding: var(--padding-md2) var(--padding-lg);
    transition: var(--transition-color-slow);
}
.Button:hover {
    background-color: var(--primary-color-light);
}

.ButtonColor {
    background-color: rgba(255, 255, 255, 0.7) !important;
    color: var(--primary-color) !important;
}
.ButtonColor:hover {
    background-color: #fff !important;
}


.Hero {
    overflow: hidden;
}

@media (max-width: 991.98px) {
    .Hero {
        padding: 32px;
    }
}

.Hero > img {
    width: 100%;
}

.Hero > div {
    margin-bottom: 0;
}

/* Basically .row .p-5 */
.Hero > div > div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.Hero > div, .Hero > div > div {
    height: 100%;
}

.HeroInfo {

}

.center .HeroTitle, .center .HeroDescription {
    text-align: center !important;
}

.HeroTitle {
    /* color: var(--color-light); */
    color: #fff;
    font-size: var(--font-size-xxl);
    font-weight: 700;
    margin: 0 0 var(--margin-sm) 0;
    letter-spacing: -0.01em;
    text-transform: uppercase;
}

.HeroDescription {
    /* color: var(--primary-text-color-dark); */
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 var(--margin-lg) 0;
}