.BitcoinIcon {
    color: var(--color-green);
    position: relative;
    font-size: 28px;
    top: -24px;
    margin-left: var(--margin-sm);
}

.BottomText {
    color: rgba(255,255,255,0.4);
    display: block;
    padding: var(--padding-md) 0 0 0;
    text-align: center;
}

.BuyButton {
    background-color: #000;
    border-radius: 4px;
    color: #fff !important;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: var(--margin-md);
    padding: 24px 24px;
    transition: all .15s ease-out;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
    white-space: nowrap;
}

.BuyButton:hover {
    background-color: var(--color-purple);;
    transform: scale(1.02);
}

.ButtonInLibrary {
    background-color: rgba(0, 0, 0, 0.035) !important;
    border: 1px solid rgba(0, 0, 0, .07) !important;
    color: #333 !important;
    font-size: 14px;
    padding: 12px 24px !important;
    margin: 0 auto 12px auto !important;
    pointer-events: none;
    cursor: default;
    width: 100%;
}

.Button.Mui-disabled {
    color: #fff !important;
}

.Button:hover {
    
}

.Button.outlined {
    background-color: none !important;
}

.cardHeader {
    color: #0E0E11 !important;
    /* animation: var(--animation-background-color-rainbow); 
    -webkit-animation: var(--animation-background-color-rainbow-webkit);  */
    padding: 24px !important;
}

.cardSubheader {
    color: rgba(0,0,0,0.4);
    font-size: 18px;
    font-weight: 600;
    margin: 0 0 var(--margin-lg) 0;
}

.cardTitle {
    color: rgba(0,0,0,0.8);
    text-align: left;
    margin: 0 0 8px 0;
}

.CheckoutButton:link, .CheckoutButton:visited {
    border: 2px solid #fff;
    border-radius: var(--button-radius-sm);
    color: #fff;
    font-size: var(--font-size-md);
    font-weight: var(--font-weight-md);
    padding: var(--padding-md) var(--padding-lg);
    transition: color .1s ease-in-out,background-color .1s ease-in-out,border-color .1s ease-in-out;
}
.CheckoutButton:hover, .CheckoutButton:active {
    background-color: #fff;
    color: var(--primary-color);
}

.CheckoutButtonContainer {
    text-align: center;
    margin-bottom: 73px;
}

.CheckoutIcon {
    margin-right: 8px;
    transition: color .1s ease-in-out,background-color .1s ease-in-out,border-color .1s ease-in-out;
}

.CryptoAssetIcon {
    max-width: 120px;
}

.CryptoContainer {
    margin-top: var(--margin-lg);
    padding: var(--margin-xl) var(--margin-xxl);
}

.CryptoCollection {
    max-width: 100%;
}

.CryptoCollectionContainer {
    background-color: #fff;
    border-radius: 4px;
    border-top: 12px solid #ccc;
    animation: var(--animation-border-color-rainbow); 
    -webkit-animation: var(--animation-border-color-rainbow-webkit);
    box-shadow: var(--shadow) var(--shadow-color-xs);
    margin-top: var(--margin-lg);
    padding: var(--margin-xl);
}

@media (max-width: 575px) {
    .CryptoCollectionContainer {
        border-radius: 0;
    }
}

.CryptoCollectionContainer h2 {
    color: rgba(0,0,0,0.8);
    margin: 0 0 8px 0;
}

.CryptoCollectionContainer p {
    color: #333;
}

.Description {
    background-color: rgba(0,0,0,0.05);
    border-radius: 4px;
    margin-bottom: 8px;
    padding: var(--margin-lg);
}

.Description p {
    color: rgba(51, 51, 51, .8);
    font-size: 18px;
    margin: 0 0 18px 0;
    padding: 0 0 0 12px;
}

.Description p:last-child {
    margin-bottom: 0;
}

.DescriptionIcon {
    float: left;
    margin-right: 8px;
    position: relative;
    top: 5px;
}

.InfoContainer {
    margin: var(--margin-lg) 0 0 0;
    padding-bottom: var(--margin-xl);
}

.Intro {
    color: #333;
}

.HowCryptoImage {
    max-width: 100%;
    margin-bottom: var(--margin-lg);
    position: relative;
}

@media (min-width: 769px) {
    .HowCryptoImage {
        top: 24px;
        margin-bottom: 0;
    }
}

.DownloadLink {
    background-color: #5ABE8F !important;
    border-radius: 4px;
    color: #fff !important;
    display: block;
    font-size: 20px;
    font-weight: 600;
    padding: 24px !important;
    text-transform: uppercase;
    transition: all .3s ease-out;
    width: 100%;
    white-space: nowrap;
}

.DownloadLink:hover {
    background-color: #47a076 !important;
}

.LeftColumn {

}

.LoginButton {
    background-color: #5ABE8F !important;
    border-radius: 4px;
    color: #fff !important;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-top: 12px;
    padding: 12px 24px;
    text-align: center;
    text-transform: uppercase;
}


.LoginButton:hover {
    box-shadow: none;
    background-color: #47a076 !important;
}

@media (max-width: 768px) {
    .LoginButton {
        width: 100%;
    }
}

.LogoMark {
    margin-bottom: 48px;
    max-width: 160px;
}

@media (max-width: 768px) {
    .LogoMark {
        margin-top: 18px;
    }
}

.ObtainButton {
    background-color: #5ABE8F !important;
    border-radius: 4px;
    color: #fff !important;
    display: inline-block;
    font-size: 16px;
    font-weight: 500;
    margin-top: 12px;
    padding: 12px 24px;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
}


.ObtainButton:hover {
    box-shadow: none;
    background-color: #47a076 !important;
}

@media (max-width: 768px) {
    .ObtainButton {
        width: 100%;
    }
}

.ObtainCrypto h2, .ObtainCryptoHow h2 {
    margin: 0 0 8px 0;
}

.ObtainCrypto p {
    font-size: 22px;
    line-height: 1.63;
}

.ObtainCryptoHow h2 {
    /* font-weight: 600; */
    line-height: 1.2;
}

.ObtainCryptoHow ol {
    color: rgba(255,255,255,0.6);
    font-size: 22px;
}

.ObtainCryptoHow ol li {
    margin-bottom: 8px;
}

.ObtainCryptoHow ol li a:link, .ObtainCryptoHow ol li a:visited {
    color: #fff;
}

.ObtainCryptoHow ol li a:hover, .ObtainCryptoHow ol li a:active {
    text-decoration: underline;
}

.ObtainCrypto p {
    
}

.ObtainCryptoImage{
    max-width: 100%;
    position: relative;
    top: -16px;
}

@media (max-width: 768px) {
    .CryptoImageContainer {
        text-align: center;
        margin-bottom: var(--margin-lg);
    }
    .ObtainCryptoImage, .HowCryptoImage {
        max-width: 250px;
    }
}

.RightColumn {
    text-align: center;
}

.PriceCard {
    border-top: 12px solid #ccc;
    animation: var(--animation-border-color-rainbow); 
    -webkit-animation: var(--animation-border-color-rainbow-webkit);
}

.Product {
    box-shadow: var(--shadow) var(--shadow-color-xs);
}

.ProductColumnContainer {
    padding: var(--margin-xxl);
}

@media (max-width: 991px) {
    .ProductColumnContainer {
        padding: var(--margin-xl) !important;
    }
}

.ProductPrice {
    animation: var(--animation-color-rainbow-fast); 
    -webkit-animation: var(--animation-color-rainbow-fast-webkit); /* Chrome and Safari */
    font-size: 64px;
    font-weight: 800;
    color: rgba(0,0,0,0.7);
    margin: 0 0 18px 0;
    padding: 0;
}


.ProductsContainer {
    background-image: linear-gradient(rgba(0, 0, 0, 0.8), rgb(66, 9, 126)), url(../media/images/screenshots/Screenshot_B8.jpg);
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat;
}

@media (min-width: 1200px) {
    .ProductsContent {
        padding: 0 var(--padding-xxl);
    }
}

.ProductsContent {
    padding: var(--padding-xxxl) auto;
}

.ProductsHero {
    padding-bottom: var(--margin-xxl) !important;
}

.ProductsHeroHeader {
    font-size: var(--font-size-xxl);
    text-align: center;
    margin: var(--margin-xxl) 0 var(--margin-sm) 0;
}

.ProductsHeroText {
    font-size: var(--font-size-lg);
    text-align: center !important;
}



.AlphaAlert {
    background-color: var(--color-warning);
    color: #fff;
    border-radius: var(--button-radius-sm);
    padding: var(--padding-lg);
    margin-bottom: var(--margin-xl);
}

.WarningIcon {
    color: #fff;
    font-size: 19px;
    margin-right: var(--margin-lg);
}