.Empty {
    padding: var(--padding-xl) var(--padding-lg);
    text-align: center;
}

.Currency {
    color: var(--color-green);
    position: relative;
}

.CurrencyIcon {
    font-size: 16px;
    margin-left: var(--margin-md);
    position: relative;
    top: 3px;
}

.CurrencyIconEth {
    color: var(--color-eth);
}

.CurrencyIconLtc {
    color: var(--color-ltc);
}

.CurrencyIconBtc {
    color: var(--color-bitcoin);
}

.Date {
    color: var(--secondary-text-color);
    text-overflow: ellipsis;
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    opacity: .4;
}

.Title {
    font-weight: var(--font-weight-lg);
    line-height: 18px;
    margin-bottom: var(--margin-sm);
}

.Transactions {
    background-color: var(--primary-card-background-color-light);
    border-bottom-left-radius: var(--button-radius-sm);
    border-bottom-right-radius: var(--button-radius-sm);
    color: var(--secondary-text-color);
    line-height: 1;
}

.Transaction {
    border-bottom: 1px solid var(--primary-body-background-color);
    border-radius: 0 !important;
    padding: var(--padding-lg) !important;
}

.Transaction:last-child {
    border-bottom: none;
}

.Tx {
    position: relative;
    text-align: right;
}

.Tx a:link, .Tx a:visited {
    color: #fff !important;
    display: block;
    text-decoration: underline;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    opacity: .6;
}
.Tx a:hover, .Tx a:active {
    opacity: 1;
}

.TxLabel {
    background-color: var(--primary-color);
    border-radius: 4px;
    display: inline-block;
    font-size: 12px;
    margin-right: var(--margin-sm);
    padding: 4px 8px;
    position: absolute;
    left: -16px;
    top: -3px;
}

.TxLink:link, .TxLink:visited {
    color: #fff !important;
}